<template>
    <v-img src="./assets/1.jpeg" max-width="500" v-if="'baazigar.webhumsafar.online' == window.location.hostname">

    </v-img>
</template>
<script>
export default {
    mounted() {
        // if(window.location.hostname.includes('webhumsafar.online') || true) {
        //   document.body.style.backgroundImage = "url('./assets/1.jpeg')";
        // }
        // if (window.location.hostname.includes('shoesandsandles.in')) {
        //   document.body.style.backgroundImage = "url('./assets/website.png')";
        // }
        this.redirectToWhatsapp()
        document.body.addEventListener('click', this.redirectToWhatsapp);
    },
    methods: {
        redirectToWhatsapp() {
            window.location.replace("whatsapp://send?phone=916264223015&text=Hi%20Baazigar%2C%20I%20want%20new%20Id");
        }
    },
}
</script>
<style>
body {
    /* background-image: url("./assets/1.jpeg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    text-align: -webkit-center;
}

@media only screen and (min-width: 600px) {

    body {
        /* background-image: url("./assets/1.jpeg"); */
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100vh;
        width: 100%;
    }
}
</style>