<template><div></div>
      <!-- <v-img src="./assets/website.png" max-width="500" center>
	  </v-img> -->
</template>
<script>

export default {
    mounted() {
        this.redirectToWhatsapp()
        document.body.addEventListener('click', this.redirectToWhatsapp);
    },
    methods: {
        redirectToWhatsapp() {
            window.location.replace("whatsapp://send?phone=+919967945363&text=Hi%20Tatabook%2C%20I%20want%20new%20Id");
        }
    },
}
</script>
<style>
	/* body {
    background-image: url("./assets/website.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

@media only screen and (min-width: 600px) {

    body {
        background-image: url("./assets/website.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100vh;
        width: 100%;
    }
} */
</style>

